import React from 'react';
import './Styles.scss'; // Importa el archivo de estilos
import { Link  } from "react-router-dom";
import { useNavigate  } from "react-router-dom";

function IndexCont() {
  const navigate = useNavigate();
  const contado = () =>{
    navigate('/car-market/contado');
  }
  return (
    <div className="app-container">
      <div className="input-container">
        <input type="text" placeholder="Placa del vehículo" className="input-field" />
        <input type="text" placeholder="N° de identificación del comprador" className="input-field" />
        <input type="text" placeholder="N° de identificación del vendedor" className="input-field" />
      </div>
      <div className="button-container">
        <button className="custom-button" onClick={ contado }>Contado</button>
        <button className="custom-button">Contado y Credito</button>
        <button className="custom-button">Credito</button>
        <button className="custom-button" >Carro parte de pago</button>
      </div>
    </div>
  );
}

export default IndexCont;
