import React, { useState } from 'react';
import { useNavigate  } from "react-router-dom";
import { CCol, CRow } from '@coreui/react';

const Contado = () => {
	const navigate = useNavigate();
  const [formData, setFormData] = useState({
    abonos: '',
    tipoSeparacion: '',
    responsabilidadGastos: '',
    precioVehiculo: '',
    clase: '',
    marca: '',
    tipo: '',
    modelo: '',
    color: '',
    placa: '',
    chasis: '',
    motor: '',
    linea: '',
    servicio: '',
    abono1: '',
    fechaAbono1: '',
    abono2: '',
    fechaAbono2: '',
    abono3: '',
    fechaAbono3: '',
    fechaContrato: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data submitted:', formData);
  };

  const volver = () =>{
	navigate('/car-market');
  }

  return (
     <div className="contado">
     	 <form className="car-form" onSubmit={handleSubmit}>
      <h2 className="car-title">{formData.placa || 'LNL459 (placa del carro)'}</h2>

      <div className="form-row">
        <div className="form-group">
          <label>Nº de abonos</label>
          <input
            type="text"
            name="abonos"
            value={formData.abonos}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Tipo de separación</label>
          <select name="tipoSeparacion" value={formData.tipoSeparacion} onChange={handleChange}>
            <option value="">Seleccione</option>
            <option value="tipo1">Tipo 1</option>
            <option value="tipo2">Tipo 2</option>
          </select>
        </div>

        <div className="form-group">
          <label>Responsabilidad de gastos</label>
          <select name="responsabilidadGastos" value={formData.responsabilidadGastos} onChange={handleChange}>
            <option value="">Seleccione</option>
            <option value="responsable1">Responsable 1</option>
            <option value="responsable2">Responsable 2</option>
          </select>
        </div>

        <div className="form-group">
          <label>Precio del Vehículo</label>
          <input
            type="text"
            name="precioVehiculo"
            value={formData.precioVehiculo}
            onChange={handleChange}
          />
        </div>
      </div>
      <CRow>
      	<CCol xs={3}>
      		<div className="form-group">
          <label>Clase</label>
          <input
            type="text"
            name="clase"
            value={formData.clase}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Marca</label>
          <input
            type="text"
            name="marca"
            value={formData.marca}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Tipo</label>
          <input
            type="text"
            name="tipo"
            value={formData.tipo}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Modelo</label>
          <input
            type="text"
            name="modelo"
            value={formData.modelo}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Color</label>
          <input
            type="text"
            name="color"
            value={formData.color}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Placa</label>
          <input
            type="text"
            name="placa"
            value={formData.placa}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Chasis</label>
          <input
            type="text"
            name="chasis"
            value={formData.chasis}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Motor</label>
          <input
            type="text"
            name="motor"
            value={formData.motor}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Línea</label>
          <input
            type="text"
            name="linea"
            value={formData.linea}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Servicio</label>
          <input
            type="text"
            name="servicio"
            value={formData.servicio}
            onChange={handleChange}
          />
        </div>
      	</CCol>
      	<CCol xs={9}>
      		<CRow className="mb-3">
      			<CCol xs={6}>
      				<div className="form-group">
			          <label>Abono 1</label>
			          <input
			            type="text"
			            name="abono1"
			            value={formData.abono1}
			            onChange={handleChange}
			          />
			        </div>
      			</CCol>
      			<CCol xs={6}>
      				<div className="form-group">
			          <label>Fecha Abono 1</label>
			          <input
			            type="date"
			            name="fechaAbono1"
			            value={formData.fechaAbono1}
			            onChange={handleChange}
			          />
			        </div>
      			</CCol>
      			<CCol xs={12}>
      				<input
			            type="text"
			            className="form-control"
			            name="fechaAbono1"
			            placeholder="Datos del banco"
			            value={formData.fechaAbono1}
			            onChange={handleChange}
			          />
      			</CCol>
      		</CRow>
      		<CRow className="mb-3">
      			<CCol xs={6}>
      				<div className="form-group">
			          <label>Abono 2</label>
			          <input
			            type="text"
			            name="abono1"
			            value={formData.abono1}
			            onChange={handleChange}
			          />
			        </div>
      			</CCol>
      			<CCol xs={6}>
      				<div className="form-group">
			          <label>Fecha Abono 2</label>
			          <input
			            type="date"
			            name="fechaAbono1"
			            value={formData.fechaAbono1}
			            onChange={handleChange}
			          />
			        </div>
      			</CCol>
      			<CCol xs={12}>
      				      				<input
			            type="text"
			            className="form-control"
			            name="fechaAbono1"
			            placeholder="Datos del banco"
			            value={formData.fechaAbono1}
			            onChange={handleChange}
			          />
      			</CCol>
      		</CRow>
      		<CRow className="mb-3">
      			<CCol xs={6}>
      				<div className="form-group">
			          <label>Abono 3</label>
			          <input
			            type="text"
			            name="abono1"
			            value={formData.abono1}
			            onChange={handleChange}
			          />
			        </div>
      			</CCol>
      			<CCol xs={6}>
      				<div className="form-group">
			          <label>Fecha Abono 3</label>
			          <input
			            type="date"
			            name="fechaAbono1"
			            value={formData.fechaAbono1}
			            onChange={handleChange}
			          />
			        </div>
      			</CCol>
      			<CCol xs={12}>
      				<input
			            type="text"
			            className="form-control"
			            name="fechaAbono1"
			            placeholder="Datos del banco"
			            value={formData.fechaAbono1}
			            onChange={handleChange}
			          />
      			</CCol>
      		</CRow>
      		<CRow className="mb-3">
      			<CCol xs={12}>
      				<div className="form-group">
				        <label>Fecha de celebración de contrato</label>
				        <input
				          type="date"
				          name="fechaContrato"
				          value={formData.fechaContrato}
				          onChange={handleChange}
				        />
				      </div>
      			</CCol>
      		</CRow>
      
      	</CCol>
      </CRow>

      <div className="form-actions">
        <button type="submit" className="save-btn">Guardar</button>
        <button type="button" className="cancel-btn" onClick={ volver }>Cancelar</button>
      </div>
    </form>
     </div>
  );
};

export default Contado;
