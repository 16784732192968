import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react'
import { AppSidebarNav } from './AppSidebarNav'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { useAuthUser } from 'react-auth-kit';
import { cilSpeedometer, cilCarAlt, cilChart, cilChartLine, cilGarage, cilListRich, cibStorybook, cibStatuspage, cilFile, cilFlagAlt, cilMap } from '@coreui/icons';
import { CNavItem } from '@coreui/react'
import CIcon from '@coreui/icons-react'
// sidebar nav config

import logo from './../assets/images/logo.png'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const authUser = useAuthUser();
  const [items, setItems] = useState([]);

  useEffect(() => {
    saveItems();
  }, [])

  const saveItems = () =>{
    let _nav = [];
    if(authUser().rol === 1){
      _nav = [
        {
          component: CNavItem,
          name: 'Dashboard',
          to: '/dashboard',
          icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Precios',
          to: '/prices',
          icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Bitácora',
          to: '/bitacora',
          icon: <CIcon icon={cilChart} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Ventas',
          to: '/ventas',
          icon: <CIcon icon={cilChartLine} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Traslados',
          to: '/conductor?page=1',
          icon: <CIcon icon={cilGarage} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Vehiculos',
          to: '/vehiculos',
          icon: <CIcon icon={cilCarAlt} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Clientes',
          to: '/clientes',
          icon: <CIcon icon={cibStorybook} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Retomas',
          to: '/retomas',
          icon: <CIcon icon={cilCarAlt} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Para Publicar',
          to: '/recepcion',
          icon: <CIcon icon={cilCarAlt} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Acta de recepción',
          to: '/actas-recepcion',
          icon: <CIcon icon={cilFile} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Estado de trámites',
          to: '/estado-de-tramites',
          icon: <CIcon icon={cilFlagAlt} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Bonos',
          to: '/bonos',
          icon: <CIcon icon={cibStatuspage} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Pruebas de ruta',
          to: '/prueba-ruta',
          icon: <CIcon icon={cilMap} customClassName="nav-icon" />,
        },
        /*{
          component: CNavItem,
          name: 'Tripipe',
          to: '/tripipe',
          icon: <CIcon icon={cilMap} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'CarMarket',
          to: '/car-market',
          icon: <CIcon icon={cilMap} customClassName="nav-icon" />,
        },*/
      ]
    }else if(authUser().rol === 2){
      _nav = [
        {
          component: CNavItem,
          name: 'Dashboard',
          to: '/',
          icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Registros',
          to: '/ventas',
          icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Registrar',
          to: '/registrar',
          icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Bitácora',
          to: '/bitacora',
          icon: <CIcon icon={cilChart} customClassName="nav-icon" />,
        }
      ]
    }else if(authUser().rol === 3){
      _nav = [
        {
          component: CNavItem,
          name: 'Dashboard',
          to: '/',
          icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Traslados',
          to: '/conductor',
          icon: <CIcon icon={cilGarage} customClassName="nav-icon" />,
        }
      ]
    }else if(authUser().rol === 4){
      _nav = [
        {
          component: CNavItem,
          name: 'Dashboard',
          to: '/',
          icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Estado de trámites',
          to: '/estado-de-tramites',
          icon: <CIcon icon={cilFlagAlt} customClassName="nav-icon" />,
        }
      ]
    }
    setItems(_nav);
  }
  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
      className='sidebarC'
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <img src={logo} className="logo" alt="logo" />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={items} />
        </SimpleBar>
      </CSidebarNav>
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
